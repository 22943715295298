import React, { useState } from 'react'
import Layout from '../../components/Layout'
import DonationList from '../../components/DonationList'

const DonatePage = () => {
    const list = [

    ];

    return (<Layout>
        <section className="section">
        <div className="container">
            <div className="content">
                <div className="centered">
                    <p>¡Vecinas! ¡Vecinos!</p>
                    <p>Cumplimos un año con ReciclApp.Hoy solicitamos tu valioso apoyo. Juntos por un futuro con nuestros niños y estudiantes.
                        Estamos pidiendo una colaboración monetaria en una <a href="https://micochinito.com/projects/1693">colecta crowdfunding</a> con grandes recompensas para ti</p>
                    <p>Protege el futuro, logremos más estudiantes.</p>
                    <p>Dona, comparte, transforma.</p>
                    <p><a href="https://micochinito.com/projects/1693">https://micochinito.com/projects/1693</a></p>
                    <p>BBVA CLABE: 012420001187392509</p>
                    <p>Nombre: RECYCLING APP TECHNOLOGIES "ReciclApp"</p>
                    <p>Concepto: Donativo</p>
                </div>
                <iframe width="100%" height="600" src="https://www.youtube.com/embed/5R1uu3zA-Kk?controls=0">
                </iframe>
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light centered">Gracias héroes del reciclaje</h2>

                <DonationList  data={list} />
                
            </div>
        </div>
        </section>
    </Layout>

    )
}

export default DonatePage