import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types'


const DonationList = ({ data }) => {

    const createColumns = (numberOfColumns) =>
    {
        let content = [];
        
        let totalItems = data.length;
        let ItemsPerColumn = Math.floor(totalItems / numberOfColumns);
        let remainingItems = totalItems % numberOfColumns;

        for(let i = 0; i < numberOfColumns; i++)
        {
            content.push(
            <Col>
                {createRecords(i, ItemsPerColumn, remainingItems, numberOfColumns)}
            </Col>);
        }
        return content;
    }

    const createRecords = (OffSet, ItemsToCreate, remainingItems, numberOfColumns) =>
    {
        let content = [];
          
        let initialValue = OffSet * ItemsToCreate;
        let finalValue = initialValue + ItemsToCreate;

        for(let i = initialValue; i < finalValue; i++)
        {
            content.push(createRecord(i));
        }

        if(remainingItems > 0 && OffSet < remainingItems)
        {
            content.push(createRecord(numberOfColumns * ItemsToCreate + OffSet));           
        }

        return content;
    }

    const createRecord = (index) =>
    {
        let content = [];
        if(data[index].quote === undefined )
            content.push(<p>{data[index].name}</p>);
        else
            content.push(
                <OverlayTrigger overlay={<Tooltip id={`total total-${index}`}>{data[index].quote}</Tooltip>}>
                    <p style={{fontWeight: 'bold'}}>{data[index].name}</p>
                </OverlayTrigger>
        );
        return content;
    }

    return (
        <Row xs={2} md={4}>
            {createColumns(4)}
        </Row>
        )
}

DonationList.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        quote: PropTypes.string
      })
    ),
  }

  export default DonationList